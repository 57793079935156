@import './mixins'; 


.aria_btn {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  position: relative;
  line-height: inherit;
  text-align: initial;
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.cta_link {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .cta_link {
    margin-left: 2em;
  }
  span {
    display: inline-block;
    font-size: inherit;
    line-height: 1;
    position: relative;
    &:before {
      background-color: transparent;
      bottom: -2px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 100%;
    }
  }
  @include screendm(desktop) {
    &:hover {
      span:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    span:before {
      background-color: currentColor;
    }
  }
}


.menu {
  background-color: #fff;
  box-sizing: border-box;
  color: #333;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  min-height: 100%;
  height: -webkit-stretch;
  height: stretch;
  left: 0;
  padding: 1.5em 0.75rem 0 1.5rem;
  position: fixed;
  pointer-events: none;
  top: 0;
  transform: translate(-100%, 0);
  transition: opacity 0.1s ease-out, transform 0.3s cubic-bezier(0,.83,.15,.97);
  width: 30em;
  z-index: 900;
  &.off {
    transition: opacity 0.1s ease-out 0.3s, transform 0.3s cubic-bezier(0,.83,.15,.97);
  }
  &[aria-hidden="false"] {
    display: flex;
    opacity: 1;
    pointer-events: initial;
    transform: translate(0, 0);
  }
}

[is-t] {
  @include screendm(mobile) {
    .menu {
      width: 100vw;
    }
    .nd_head-sub_group {
      font-size: 4.5vw;
    }
    .top_link {
      font-size: 6.5vw;
      font-weight: 400;
    }
    .group {
      font-size: 4vw;
    }
    .back {
      font-size: 4.2vw;
    }
    .bottom {
      .link {
        font-size: 3.5vw;
      }
    }
  }
}

.logo {
  display: inline-block;
  width: fit-content;
  svg {
    display: inline-block;
    height: 1.67em;
    path {
      fill: currentColor;
    }
  }
}

.close {
  position: absolute;
  right: 0.75em;
  top: 1.5em;
  path {
    stroke-width: 1px;
  }
}

.icon_close {
  display: inline-block;
  height: auto;
  width: 1.05em;
}

.content {
  background-color: #fff;
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
  margin: 7em 0 0;
  transform: translate(-20vw,0);
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 2;
  &:empty {
    flex: 0 1 auto;
    margin-top: 1em;
  }
  a {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &.contentscroll {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.contentscroll::-webkit-scrollbar {
  background-color: #dddad0;
  height: 4px;
  width: 1px;
}

.contentscroll::-webkit-scrollbar-thumb {
  background: #333;
}

.cont_menu {
  opacity: 1;
  transform: translate(0,0);
  transition: all 0.2s ease-in-out;
}

.panel {
  max-height: 0;
  pointer-events: none;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &[aria-hidden="false"] {
    margin-bottom: 2em;
    max-height: unset;
    opacity: 1;
    pointer-events: initial;
  }
  .top_link {
    margin-bottom: 1.5rem;
    text-decoration: underline;
  }
}


.features {
  font-weight: bold;
  margin-bottom: 2.5em;
}

.journal_link {
  font-weight: normal;
}

.promos {
  display: flex;
  column-gap: 1em;
  margin-top: 4rem;
  padding-right: 0.75rem;
  a {
    font-family: $font-acumincond;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.3;
    text-transform: uppercase;
  }

  p {
    margin-top: 0.5em;
  }
}

.promo_img {
  height: auto;
  width: 100%;
}

.journal_group {
  margin-top: 3.5rem;
}



.promo_item {
  flex: 1 1 50%;
  max-width: 50%;
}

.group {
  li ~ li {
    margin-top: 0.5em;
    .title {
      margin-top: 2em;
    }
  }
  ~ .group {
    margin-top: 0.5em; 
    &.journ_group {
      margin-top: 2em;
    }
  }
  .title {
    @include smoothfont;
    font-family: $font-acumincond;
    font-size: 1em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.75em;
  }
  .title + .sub_group {
    .subtitle {
      margin-top: 0.75em;
    }
  }
  .sub_group {
    ~ .sub_group {
      margin-top: 1.5em;
    }
  }
  .subtitle {
    margin-top: 2em;
    font-weight: 500;
  }
}

.journ_group {
  .group + .group {
    margin-top: 2em;
  }
}

.sub {
  .sub ~ .sub {
    margin-top: 0.5em; 
  }
  .top_link {
    margin-bottom: 1.5em;
  }
}

.top_link {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.3;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: unset;
  svg {
    display: inline-block;
    height: 0.75em;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0,-45%);
    width: auto;
  }
}

.link {
  @include smoothfont;
  position: relative;
  text-decoration: none;
  &:before {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  &:active {
    &:before {
      background-color: currentColor;
    }
  }
}

.back {
  left: 1.5rem;
  top: 6rem;
  position: absolute;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: translate(-20vw,0);
}

.bottom {
  display: flex;
  flex-direction: column;
  padding: 1em 0 10vh;
  opacity: 1;
  transform: translate(0,0);
  transition: all 0.2s ease-in-out;
  row-gap: 1.5em;
  .top_link,
  .acctlink {
    transition: opacity 0.2s ease-in-out;
  }
}




.bg {
  background-color: rgba(#fff, 0);
  backdrop-filter: blur(0) brightness(100%) saturate(100%);
  -webkit-backdrop-filter: blur(0) brightness(100%) saturate(100%);
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  // transform: translate(100%, 0);
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 470;
  &[aria-hidden="false"] {
    backdrop-filter: blur(3px) brightness(85%) saturate(80%);
    -webkit-backdrop-filter: blur(3px) brightness(85%) saturate(80%);
    background-color: rgba(#fff, 0.5);
    opacity: 1;
    pointer-events: auto;
    @include screendm(mobile) {
      height: 100vh;
      height: 100dvh;
      width: 100vw;
    }
  }
}






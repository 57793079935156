@import './mixins'; 


.title_est {
  font-family: $font-acumin;
  font-size: 0.9em;
  font-weight: normal;
  margin-top: 3em;
  text-transform: none;
  p {
    @include nd_title;
    font-size: 3em;
    font-weight: bold;
    line-height: 1;
    margin: 1rem 0 0;
  }
}

.multiship {
  align-items: flex-end;
  display: flex;
  margin: 2em 0 4em;
  button {
    font-size: 0.9em;
  }
  @include screendm(desktop) {
    column-gap: 4em;
  }
  @include screendm(mobile) {
    // justify-content: space-between;
    column-gap: 3em;
  }
}

.mlabel {
  @include nd_title;
  font-family: $font-acumin;
  font-size: 0.9em;
  font-weight: normal;
  text-transform: capitalize;
}

.mstatus {
  @include nd_title;
  font-family: $font-acumin;
  font-size: 0.9em;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.mcode {
  font-size: 0.8em;
}

.mdate {
  @include nd_title;
  font-size: 1.75em;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.bar {
  background-color: #DDDAD0;
  height: 5px;
  margin-top: 2.5em;
  position: relative;
}

.bar_circ {
  background-color: #333;
  border-radius: 9999px;
  height: 1rem;
  left: 50%;
  position: absolute;
  top: -1.7em;
  transform: translate(-50%, 0);
  width: 1rem;
  @include screendm(mobile) {
    top: -1.95em;
  }
}

.timeline {
  display: flex;
  margin-top: 1em;
  justify-content: space-between;
}

.timeitem {
  min-width: 8em;
  position: relative;
  text-align: center;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
  @include screendm(mobile) {
    font-size: 0.85em;
    min-width: unset;
  }
}

.prog {
  background-color: #333;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.details {
  column-gap: 2em;
  display: flex;
  margin-top: 4em;
  > div {
    flex: 1
  }
  @include screendm(mobile) {
    display: block;
  }
}

.events {
  padding-right: 1em;
  box-sizing: border-box;
  max-height: 11em;
  overflow-x: hidden;
  overflow-y: auto;
  @include screendm(mobile) {
    margin-bottom: 3.5em;
  }
}

.events::-webkit-scrollbar {
  background-color: #DDDAD0;
  height: 8px;
  width: 1px;
}

.events::-webkit-scrollbar-thumb {
  background: #333;
}

.evitem {
  border: 1px solid rgba(#000,0.1);
  border-width: 1px 0;
  line-height: 1.7;
  padding: 1.75em 0;
  + .evitem {
    border-top: 0;
  }
}

.tracknum {
  text-transform: uppercase;
  @include screendm(mobile) {
    a {
      display: block;
      margin-top: 1em;
    }
  }
}

.mobile_updates {
  @include smoothfont;
  margin-top: 3em;
}








@import './mixins'; 


.nd_foot {
  background-color: #fff;
  border-top: 1px solid #000;
  font-size: 1.1rem;
  @include screendm(desktop) {
    margin-top: 7em;
    padding: 8em 0 5em;
  }
  @include screendm(mobile) {
    margin-top: 4em;
    padding: 4em 0 3em;
  }
}

.main--article ~ #shopify-section-footer {
  .nd_foot {
    @include screendm(desktop) {
      margin-top: 0;
    }
    @include screendm(mobile) {
      margin-top: 0;
    }
  }
}

.nd_foot-content {
  @include nd_cont;
  display: grid;
  ~ .nd_foot-content {
    margin-top: 8em;
    &.nd_foot-corp {
      margin-top: 3em;
    }
  }
  @include screendm(desktop) {
    column-gap: 4em;
    grid-template-columns: repeat(4, 1fr);
  }
  @include screendm(mobile) {
    column-gap: 1em;
    grid-template-columns: repeat(3, 1fr);
    ~ .nd_foot-content {
      margin-top: 4em;
    }
  }
}

.nd_foot-playing {
  @include screendm(desktop) {
    grid-row: span 2;
  }
  @include screendm(mobile) {
    grid-column: span 3;
    margin-bottom: 4em;
  }
}

.nd_foot-img_link {
  display: block;
  margin-top: 2.75em;
  position: relative;
  width: 80%;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    position: relative;
  }
}

.nd_foot-play_img {
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  min-width: 100%;
  min-height: 100%;
}

.nd_foot-play_store {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 1.15em;
  letter-spacing: 0.02em;
  margin-top: 1em;
  text-transform: uppercase;
  a {
    @include nd_cta;
    display: inline-block;
    font-size: 1rem;
    font-weight: normal;
    transform: translate(0,-0.1em);
  }
  p {
    margin-top: 1em;
  }
}

.nd_foot-title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  margin-bottom: 2em;
  text-transform: uppercase;
  @include screendm(mobile) {
    margin-bottom: 1.5em;
  }
}

.nd_foot-links {
  li {
    ~ li {
      margin-top: 0.25em;
    }
  }
  a {
    @include smoothfont;
    display: inline-block;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    @include screendm(mobile) {
      font-size: 3.2vw;
      line-height: 1.2;
    }
  }
}

.nd_foot-corp {
  @include smoothfont;
  font-size: 1rem;
  font-family: $font-acumincond;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  strong {
    display: block;
    margin-top: 2.5em;
  }
  @include screendm(mobile) {
    grid-column: span 3;
    margin-bottom: 2em;
    strong {
      margin-top: 1.5em;
    }
  }
}

.nd_foot-legal {
  a, .aria_btn {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .aria_btn {
    font-size: inherit;
    height: 1em;
  }
  @include screendm(desktop) {
    display: flex;
    font-size: 0.85em;
    grid-column: span 3;
    justify-content: space-between;
  }
  @include screendm(mobile) {
    display: flex;
    grid-column: span 3;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 2em;
    a, .aria_btn {
      flex: 1;
      font-size: 0.75em;
      white-space: nowrap;
      &:nth-child(3),
      &:nth-child(5) {
        text-align: right;
      }
    }
  }
}

.nd_foot-contact {
  position: relative;
  @include screendm(desktop) {
    grid-column: 3 / span 2;
    margin-top: 6em;
  }
  @include screendm(mobile) {
    grid-column: span 3;
    margin-top: 3.5em;
  }
  input[type="email"],
  input {
    @include smoothfont;
    background-color: transparent;
    border: 0 !important;
    box-shadow: none;
    font-family: $font-mono !important;
    font-size: 16px !important;
    padding: 0 0 0.5em 0!important;
    &::-webkit-input-placeholder,
    &::placeholder {
      color: #000;
      font-family: $font-mono !important;
      font-size: 16px !important;
    }
  }
  .klaviyo-form.klaviyo-form.klaviyo-form {
    padding: 0 !important;
  }
  .needsclick[component]:first-child {
    padding-left: 0 !important;
    > div.needsclick {
      border-bottom: 1px solid #000;
    }
  }
  [data-testid="form-component"] + [data-testid="form-component"] {
    padding: 0 !important;
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    button[type="button"] {
      @include arr_btn;
      width: 5em;
      @include screendm(mobile) {
        width: 3em;
      }
    } 
  }
}

@import './mixins'; 


.aria_btn {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  position: relative;
  line-height: inherit;
  text-align: initial;
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.cta_link {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .cta_link {
    margin-left: 2em;
  }
  span {
    display: inline-block;
    font-size: inherit;
    line-height: 1;
    position: relative;
    &:before {
      background-color: transparent;
      bottom: -2px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 100%;
    }
  }
  @include screendm(desktop) {
    &:hover {
      span:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    span:before {
      background-color: currentColor;
    }
  }
}

.deskhead {
  display: none;
  // @include screendm(desktop) {
  //   display: flex;
  // }
  @include screendm(deskmed) {
    display: flex;
  }
}

.mobhead {
  display: none;
  @include screendm(desksm) {
    display: flex;
  }
  @include screendm(mobile) {
    display: flex;
  }
  .nd_head-top_btn {
    color: inherit;
    .hamberger path {
      fill: currentColor;
    }
  }
}

[is-t] {
  .mobhead {
    display: flex;
  }
  .deskhead {
    display: none;
  }
}

.nd_head {
  background-color: #fff;
  border: 1px solid transparent;
  border-width: 0 0 1px;
  box-sizing: border-box;
  color: #333;
  font-size: 1.1rem;
  padding: 4em 0 2em;
  position: sticky;
  top: 0;
  transition: background-color 0.25s ease-in-out;
  width: 100%;
  z-index: 400;
  &.on {
    transition: all 0.25s ease-in-out;
  }
  @include screendm(mobile) {
    border-bottom: 0;
    padding: 3em 1em 0;
    .head-logo_svg {
      height: 1.5em;
    }
  }
  .icon-hamberger {
    path {
      stroke: currentColor;
    }
  }
  &[full="true"] {
    @include screendm(desktop) {
      background-color: transparent;
      border-color: transparent !important;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
    @include screendm(mobile) {
      color: #333;
    }
  }
  &[mfull="true"] {
    @include screendm(mobile) {
      background-color: transparent;
      border-color: transparent !important;
    }
  }
}

.sub_head {
  position: relative;
}

.head-logo_svg {
  height: 2em;
  padding: 0;
  width: auto;
}

.svg-logo {
  height: 100%;
  width: auto;
  display: block;
  fill: currentColor;
  transition: all 0.1s linear;
}

#shopify-section-header_new.nd_fullbleed_header {
  background-color: transparent;
  color: #fff;
  position: fixed;
  transition: all 0.2s ease-in-out;
  &.on {
    background-color: #fff;
    color: #000
  }
}

.nd_head-content {
  @include nd_cont;
  @include screendm(mobile) {
    padding: 0;
  }
}

.nd_head-top {
  align-items: flex-end;
  button, a:not(.head-logo_link):not(.gend_link) {
    @include smoothfont;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    line-height: 1;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: unset;
  }
}



.nd_head-top_btn {
  @include aria_btn;
  color: #333;
  font-family: $font-acumincond;
  font-size: 1.15em;
  letter-spacing: 0.02em;
  transition: all 0.2s linear;
  position: relative;
  &:before {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: #333;
      &:before {
        background-color: currentColor;
      }
    }
  }
  &:active,
  &[aria-expanded="true"] {
    &:before {
      background-color: currentColor;
    }
  }
  &.logged_in path {
    fill: currentColor;
  }
  svg,
  .cart_qty {
    pointer-events: none;
  }
}

.nd_head-top_btn.wardbtn {
  svg {
    height: 0.95em;
    width: auto;
  }
  path {
    fill: none;
    stroke: currentColor;
    stroke-width: 2.5em;
    stroke-linecap: round;
  }
}

.nicon {
  display: inline-block;
  height: auto;
  width: 1.2em;
  path, circle, line {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 7px;
  }
  @include screendm(mobile) {
    width: 1.175em;
  }
}


.nd_head-main {
  align-items: flex-end;
  column-gap: 2em;
  display: flex;
  flex: 1;
}

.nd_head-aux {
  align-items: flex-end;
  display: flex;
  column-gap: 2em;
  flex: 0 1 auto;
  .icon {
    height: 1.3em;
  }
  @include screendm(mobile) {
    align-items: center;
    column-gap: 1.5em;
  }
}

.nd_cart-on {
  svg path:first-child {
    fill: #82311F;
  }
}

.cart_qty {
  @include smoothfont;
  @include font-overp;
  background-color: transparent;
  box-sizing: border-box;
  color: #fff;
  display: none;
  font-size: 8px;
  font-weight: 600;
  left: 50%;
  line-height: 14px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%,-45%);
  z-index: 3;
  &:not(:empty) {
    display: block;
  }
}


.nd_head-panels {
  background-color: transparent;
  color: #333;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  &.open {
    background-color: #fff;
    max-height: 70em;
  }
  @include screendm(mobile) {
    display: none;
  }
}

.panel {
  display: flex;
  font-size: 0.9em;
  column-gap: 2em;
  padding: 1em 0 3em;
}

.nd_head-sub {
  padding: 0 0 4em;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: 100%;
  z-index: -1;
  &[aria-expanded="true"] {
    opacity: 1;
    pointer-events: initial;
    position: relative;
    z-index: 2;
  }
  .title {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
}

.sub_group,
.group_wrap {
  font-size: 1em;
  flex: 1;
  li ~ li {
    margin-top: 0.5em;
  }
}

.group_wrap {
  .title {
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.75em;
  }
  .title + .sub_group {
    .subtitle {
      margin-top: 0.75em;
    }
  }
  .sub_group {
    ~ .sub_group {
      margin-top: 1.5em;
    }
  }
  .subtitle {
    margin-top: 2em;
    font-weight: 500;
  }
}

.features {
  font-weight: bold;
}

.journal_link {
  font-weight: normal;
}

.journ_prev {
  flex: 1 1 24em;
  max-width: 24em;
  img {
    height: auto;
    width: 100%;
  }
  p {
    @include nd_title;
    font-size: 0.95em;
    margin-top: 1em;
  }
}

.journ_link {
  text-decoration: none;
  @include screendm(desktop) {
    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }
}

.journ_link,
.journ_place {
  display: block;
  height: 100%;
  max-height: 95%;
  width: 100%
}

.title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 1.05em;
  font-weight: normal;
  letter-spacing: 0.02em;
  margin-top: 2.5em;
  text-transform: uppercase;
}



.nd_head-link {
  @include smoothfont;
  font-size: 1em;
  position: relative;
  text-decoration: none;
  &:before {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  @include screendm(desktop) {
    &:hover {
      &:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    &:before {
      background-color: currentColor;
    }
  }
}

.promos {
  display: flex;
  column-gap: 1.5em;
  .nd_head-link {
    font-family: $font-acumincond;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.3;
    text-transform: uppercase;
  }
  p {
    font-size: 0.95em;
    margin-top: 0.75rem;
  }
  a {
    @include nd_title;
    font-size: 0.95em;
    font-weight: 700;
    text-decoration: none;
  }
}

.promo_img {
  height: auto;
  width: 100%;
}

.promo_item {
  flex: 1 1 13vw;
  max-width: 13.5em;
}


.mobhead {
  align-items: center;
  flex-wrap: wrap;
  // @include screendm(desktop) {
  //   display: none;
  // }
}

.mobhead_sec {
  align-items: center;
  column-gap: 1.5em;
  display: flex;
  flex: 1;
  &.mobhead_right {
    justify-content: flex-end;
  }
}

.mobhead_gend {
  border: 1px solid #dddad0;
  border-width: 1px 0;
  box-sizing: border-box;
  display: none;
  flex: 1 1 100%;
  margin: 1rem -1rem 0;
  padding: 0 1rem;
  a {
    @include nd_title;
    flex: 1;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1;
    padding: 0.95rem 0 1rem;
    text-align: center;
    text-decoration: none;
    &:first-child {
      border-right: 1px solid #dddad0;
    }
    &.curr {
      text-decoration: underline;
    }
    &:active {
      background-color: #333;
      color: #fff;
    }
  }
  @include screendm(mobile) {
    display: flex;
  }
}

.banner {
  @include smoothfont;
  background-color: #f3f1ef;
  color: #333;
  font-size: 0.95rem;
  left: 0;
  line-height: 1;
  padding: 0.65em 0 0.75em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  .banner {
    opacity: 1;
    transition: opacity 0.2s linear;
  }
  @include screendm(mobile) {
    border-bottom: 1px solid #dddad0;
  }
}

[ab-banner] {
  .nd_head {
    padding-top: 4em;
    @include screendm(mobile) {
      padding-top: 3em;
    }
  }
}




@import './mixins'; 

.home_blocks {
  margin-top: 5em;
  padding: 7em 0 7em;
  position: relative;
  &:before {
    background-color: #d9d9d9;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0);
    @include screendm(desktop) {
      max-width: 1350px;
      width: 83%;
    }
  }
  @include screendm(mobile) {
    margin-top: 4em;
    padding-top: 3em;
    &:before {
      width: calc(100% - 2em);
    }
  }
}

.welcome_or {
  border-top: 1px solid #dddad0;
  line-height: 1;
  margin: 2.5em auto 1em;
  max-width: 60%;
  text-align: center;
  > div {
    @include nd_title;
    background-color: #fff;
    display: inline-block;
    font-size: 0.85em;
    padding: 0 1em;
    transform: translate(0, -67%);
  }
}

.welcome_content {
  max-width: 50em;
}

.welcome_copy {
  margin-top: 2.5em
}
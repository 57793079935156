@import './mixins'; 


.step {
  border-top: 1px solid rgba(#000,0.1);
  margin-top: 3em;
  padding: 4em 0 6em;
}

.breadcrumbs {
  display: flex;
  font-size: 0.95em;
  column-gap: 0.25em;
  margin: 2rem 0 1.5em 0;
  @include screendm(mobile) {
    > div {
      font-size: 3.5vw;
    }
  }
}

.reset {
  @include screendm(desktop) {
    float: right;
  }
  @include screendm(mobile) {
    display: block;
    margin-top: 1em;
  }
}

.hist_item {
  @include smoothfont;
  background-color: transparent;
  border: 1px solid rgba(#000,0.1);
  border-width: 1px 0;
  box-sizing: border-box;
  column-gap: 1.5em;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  outline: 0;
  padding: 2em 0;
  position: relative;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;
  + .hist_item {
    border-top: 0
  }
  &.selected {
    border-color: #7a7a6b;
    background-color: #7a7a6b;
    color: #fff;
    .order_item-status > div {
      color: #fff;
    }
  }
  &.disable {
    background-color: #eee;
    border-color: #eee;
    cursor: default;
  }
  &.static {
    background-color: transparent;
    border: 1px solid rgba(#000,0.2);
    cursor: default;
  }
  &.open {
    .prods {
      display: grid;
    }
    .caret {
      transform: rotate(180deg);
    }
  }
}

.title {
  @include nd_title;
  font-size: 1.2em;
  font-weight: bold;
  flex: 1;
  @include screendm(mobile) {
    flex: 1 1 100%;
    margin-bottom: 1em;
  }
}

.infos {
  column-gap: 1em;
  display: flex;
  flex: 1;
  text-align: left;
}

.info {
  flex: 1;
  white-space: nowrap;
  @include screendm(mobile) {
    flex: 1;
    font-size: 0.95em;
    white-space: nowrap;
  }
}

.opts {
  align-items: center;
  column-gap: 1.5em;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  @include screendm(mobile) {
    flex: 1 1 100%;
    justify-content: center;
    justify-content: flex-start;
    margin-top: 2em;
    .caret {
      position: absolute;
      top: 2em;
      right: 0;
      svg {
        height: 0.75em;
        width: auto;
      }
    }
  }
}

.opt {
  @include nd_title;
  font-weight: bold;
  text-decoration: none;
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.prods {
  flex: 1 1 100%;
  display: none;
  gap: 1em 2em;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  margin-top: 1.5em;
}

.shipped {
  border: 1px solid #D9D9D9;
  border-width: 1px 0;
  column-gap: 2em;
  display: flex;
  margin-top: 2em;
  padding: 1.5em 0;
}

.shipments {
  display: grid;
  flex: 1;
  gap: 1.5em;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  line-height: 1.7;
}

.shipped_track {
  flex: 0 1 auto;
}

.item {
  border: 1px solid #D9D9D9;
  border-width: 1px 0;
  column-gap: 2em;
  cursor: pointer;
  display: flex;
  width: 100%;
  position: relative;
  padding: 2em 0;
  + .item {
    border-top: 0;
  }
  &:after {
    background-color: transparent;
    border: 1px solid #333;
    height: 1.25em;
    content: '';
    right: 0;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    transition: all 0.2s ease-in-out;
    width: 1.25em;
  }
  &.selected {
    &:after {
      background-color: #333;
    }
  }
  &.static {
    cursor: default;
    &:after {
      display: none;
    }
  }
  &[disabled] {
    opacity: 0.6;
    cursor: default;
    &:after {
      display: none;
    }
  }
  @include screendm(desktop) {
    &:hover:not([disabled]):not(.static) {
      .title {
        text-decoration: underline
      }
    }
  }
}

.getting {
  border-bottom: 1px solid #DDDAD0;
  column-gap: 3em;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 2em;
  .item {
    border: 0;
    flex: 1;
  }
}

.attributes {
  flex: 0 1 30%;
  .title {
    font-size: 1.1em;
  }
  input[type="radio"]:checked {
    + .nswatch {
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px #333;
    }
  }
  @include screendm(mobile) {
    flex: 1 1 100%;
  }
}

.color_title {
  align-items: center;
  display: flex;
  column-gap: 2em;
  .title {
    flex: 1;
  }
  p {
    flex: 0 1 auto;
  }
}

.nswatch {
  border-radius: 0;
  box-sizing: border-box;
  display: inline-block;
  height: 20px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  width: 30px;
  &.oos,
  &.oos.scolor {
    &:after,
    &:before {
      background-color: #ddd;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      transform: translate(-50%,-50%) rotate(-45deg);
      top: 50%;
      width: 1px;
    }
    &:after {
      border: 0;
      border-radius: 0;
      transform: translate(-50%,-50%) rotate(45deg);
    }
    &:hover {
      box-shadow: none;
    }
  }
  &.is_white {
    border: 1px solid #777;
  }
  &.current {

  }
}

.qsizes {
  border: 1px solid #dddad0;
  border-width: 1px 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  button,
  .option {
    @include smoothfont;
    background-color: #fff;
    font-size: 0.9em;
    line-height: 1;
    padding: 1em 0;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    @include screendm(desktop) {
      &:hover {
        background-color: #333;
        color: #fff;
      }
    }
    &:active,
    &.active {
      background-color: #333;
      color: #fff;
    }
    &[disabled] {
      color: #d9d9d9;
      pointer-events: none;
    }
  }
  label {
    border-bottom: 1px solid #dddad0;
    cursor: pointer;
    ~ label {
      border-left: 1px solid #dddad0;
    }
  }
}

.notice {
  color: #666;
  font-size: 0.75em;
  margin-bottom: 1em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0,-50%);
}

.image {
  flex: 0 1 10em;
}

.prod_info {
  @include smoothfont;
  display: flex;
  flex-direction: column;
  font-size: 0.95em;
  justify-content: space-between;
  min-height: 100%;
  align-self: stretch;
  flex-wrap: wrap;
  > div {
    flex: unset;
  }
  > div:not(.title) {
    color: #5F5D5D;
    line-height: 1.6;
    text-transform: capitalize;
  }
}

.radio {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 0.25em;
  padding: 0.25em 0;
  width: 100%;
}








@import './mixins'; 



// ACCESSIBILITY AND NO-JS STYLE STUFF -----------------------------------

@mixin visually-hidden() {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown($position: inherit) {
  position: $position !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

.visually-hidden {
  @include visually-hidden();
}

.js-focus-hidden:focus {
  outline: none;
}

.label-hidden {
  @include visually-hidden();
  .no-placeholder & {
    @include visually-shown();
  }
}

.visually-shown {
  @include visually-shown();
}

.no-js:not(html) {
  display: none;
  .no-js & {
    display: block;
  }
}

.js {
  .no-js & {
    display: none;
  }
}

.aria_btn {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  position: relative;
  line-height: inherit;
  text-align: initial;
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.supports-no-cookies:not(html) {
  display: none;

  html.supports-no-cookies & {
    display: block;
  }
}

.supports-cookies {
  html.supports-no-cookies & {
    display: none;
  }
}

.skip-link:focus {
  @include visually-shown(absolute);
  color: #333;
  background-color: #fff;
  padding: 1em;
  z-index: 10000;
  transition: none;
}

.icon-fallback-text {
  @include visually-hidden();
}



html,
body {
  background-color: #fff;
  color: #333;
  font-size: 13px;
  font-family: $font-acumin;
}

h1, h2, h3, h4, h5, h6 {
  @include nd_title;
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 0
}

h1 {
  @include title_size;
}

h2 {
  font-weight: bold;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a, button, input, select {
  &:focus {
    outline: 0
  }
}

.usingKeyboard {
  a, button, input, select {
    &:focus {
      outline: 2px solid #00bdff
    }
  }
}

a {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

p {
  @include smoothfont;
  line-height: 1.6;
}


.prod_title {
  font-size: 1.2em;
}

.cta_wr {
  margin: 2.5em 0 0;
  text-align: center;
  + .cta_wr {
    margin-top: 1em;
  }
  @include screendm(mobile) {
    .blocka {
      width: 100%;
    }
  }
}


.base {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}

.dialog_bg {
  background-color: #fff;
  background-size: cover;
}

.dialog_base {
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.welcome-logo {
  @include nd_title;
  font-size: 1.7em;
  font-weight: 500;
  text-align: left;
}

.welcome_dialog {
  @include nd_cont;
  align-self: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 3em 0 2em;
  
  .view_title {
    margin-bottom: 1.5rem;
  }

  .welcome-copy {
    line-height: 1.6;
    max-width: 50em;
  }
}

.start_returns {
  
  @include screendm(desktop) {
    .view-copy, form {
      max-width: 50em;
    }
  }

  @include screendm(mobile) {
    max-width: unset;
  }
}

.dialog {
  background-color: #fff;
  align-self: center;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 35em;
  padding: 2em;
  .welcome-logo svg {
    max-height: 2.5em;
  }
  .view_title {
    margin-bottom: 1.5rem;
  }
}

.app_view {
  @include nd_cont;
}

.view-copy {
  margin-bottom: 2em;
}

.header {
  background-color: #000;
  color: #fff;
  padding: 0.75em 0.5em 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  a {
    display: inline-block;
    text-decoration: none;
  }
  .svg-logo {
    height: 1.5em;
    width: auto;
  }
}


.svg-logo path {
  fill: currentColor;
}


.footer {
  color: #333;
  flex: 0 1 100%;
  padding: 2em 0 3em;
  text-align: center;
}


.welcome-copy {
  margin-top: 2.5em
}

.welcome-opts {
  display: flex;
  column-gap: 4em;
  margin-top: 4em;
  max-width: 50em;
  @include screendm(desktop) {
    align-items: center;
  }

  @include screendm(mobile) {
    flex-direction: column;
    row-gap: 1.5em;
  }
}

.welcome-opt {
  flex: 1;
  text-align: center;
}

.welcome-opt_link {
  color: inherit;
  display: block;
  text-decoration: none;
}

.welcome-img {
  display: block;
  height: auto;
  margin: 0 auto 1.5em;
  max-width: 40%;
  width: 100%
}

.welcome-opt_link-label {
  @include nd_title;
  background-color: #333;
  color: #fff;
  font-weight: normal;
  line-height: 1.2;
  padding: 0.7rem 0 0.9rem;
  span {
    letter-spacing: 0.05em;
  }
}

.welcome-opt_link-text {
  font-size: 1.25em;
  font-weight: bold;
}


.top_search {
  margin: 3em 0 2em;
  max-width: 30em;
  position: relative;
  text-align: center;
  input {
    font-family: $font-mono;
    margin: 0 0 1rem;
  }
  @include screendm(mobile) {
    margin-top: 1.5em;
  }
}

.store_btn {
  @include arr_btn;
  height: 1.5em;
  width: 6em;
  position: absolute;
  right: 0;
  top: 1em;
  &:before,
  &:after {
    z-index: 1;
  }
}

.icon-loc {
  height: 1.2em;
  position: absolute;
  right: 0;
  top: 1em;
  width: auto;
}

.top_search-status {
  margin-top: 4em;
}

.result {
  border-top: 1px solid #333;
  padding: 2em 0;
}

.result_detail {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1.5em 2em;
  margin: 1em 0 0;
}

.order_item {
  background-color: transparent;
  border: 1px solid rgba(#000,0.1);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  padding: 1em;
  text-align: left;
  transition: all 0.2s ease-in-out;
  width: 100%;
  + .order_item {
    margin-top: 1.5em;
  }
  &.selected {
    border-color: #7a7a6b;
    background-color: #7a7a6b;
    color: #fff;
    .order_item-status > div {
      color: #fff;
    }
    &:hover {
      background-color: #9c9c89;
    }
  }
  &:hover {
    border-color: rgba(#000,0.4);
    background-color: rgba(#fff,0.8);
  }
  &.disable {
    background-color: #eee;
    border-color: #eee;
    cursor: default;
  }
  &.static {
    background-color: transparent;
    border: 1px solid rgba(#000,0.2);
    cursor: default;
  }
}


.reason_wr {
  &.unset {
    outline: 2px solid #b73e25;
    outline-offset: 13px;
    border-radius: 4px;
    h2 {
      color: #b73e25;
    }
  }
}


.order_item-purchase {
  .order_item-title {
    flex: 1;
    @include screendm(mobile) {
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
}

.order_item-info {
  flex: 0 1 auto;
  @include screendm(mobile) {
    flex: 1;
  }
}

.order_item-prod {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5em;
  .prod_title {
    flex: 1;
  }
  @include screendm(mobile) {
    row-gap: 1.5em;
    justify-content: center;
    .prod_title {
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 0.1rem;
      order: 0;
    }
    .order_item-status {
      flex: 2;
      order: 2;
      margin-top: 0.25em;
    }
    .order_item-price {
      flex: 2;
      order: 4;
      text-align: center;
      margin-top: 0.25em;
    }
  }
}

.order_item-prod_img {
  flex: 0 1 30%;
  @include screendm(mobile) {
    flex: 0 1 50%;
    order: 1;
  }
}

.order_item-prod_info {
  flex: 1;
  @include screendm(mobile) {
    flex: 1 1 100%;
    order: 0
  }
}

.order_item-prod_color,
.order_item-prod_size {
  flex: 1 1 100%;
}

.order_item-prod_color {
  @include screendm(mobile) {
    margin-bottom: 0.5rem;
    order: 1;
    text-align: center;
  }
}

.order_item-prod_size {
  flex: 1 1 100%;
  margin-top: 1em;
  @include screendm(mobile) {
    flex: 1;
    margin-top: 0.25em;
    order: 3;
    text-align: center;
  }
}

.order_item-head {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5em;
}

.order_item-img_grid {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(4em,1fr));
  margin-top: 1.5em;
}

.order_item-notice {
  color: #666;
  font-size: 0.75em;
  margin-bottom: 1em;
  flex: 1 1 100%;
  text-align: center;
  line-height: 0.7;
}

.order_item-title {
  font-size: 1.2em;
  font-weight: bold;
}

.swatches {
  display: grid;
  gap: 0.7em;
  grid-template-columns: repeat(auto-fill, 30px);
  justify-items: center;
}

.swatch {
  border-radius: 50%;
  border: 2px solid rgba(#676845,0.4);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 0 2px #f5f4ef, 0 0 0 3px rgba(#676845, 0.4);
  }
}

.swatch-curr {
  cursor: default;
  box-shadow: 0 0 0 1px #f5f4ef, 0 0 0 2px #676845;
  &:hover,
  &:active {
    box-shadow: 0 0 0 1px #f5f4ef, 0 0 0 2px #676845;
  }
}




// FROM SITE


.pdp-selector-options {
  column-gap: 1em;
  row-gap: 0.5em;
  display: flex;
  justify-content: space-between;
}

.pdp-selector-option {
  @include smoothfont;
  border: 1px solid transparent;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1;
  min-width: 2.4em;
  padding: 0.55em 0.55em 0.6em;
  text-align: center;
  text-decoration: none;
  &:hover,
  &:active {
    border-color: #b7b7b7;
  }
  &.active {
    color: #fff;
    background-color: #333;
    border-color: #252525;
    cursor: default;
  }
  &.unavailable {
    color: #b2b2b2;
    &.active {
      border-color: #b7b7b7;
    }
  }
  @include screendm(mobile) {
    font-size: 3.5vw;
  }
}














/*================ Color Variables ================*/

// Text colors
$color-body-text: #333;

// Backgrounds
$color-body: #fff;

// Helper colors for form error states
$color-disabled: #000;
$color-disabled-border: #000;
$color-error: #b73e25;
$color-error-bg: #b73e25;
$color-success: #000;
$color-success-bg: #000;



// Overpass
// 300 - light        *
// 400 - regular      *
// 500 - medium       *
// 600 - semibold     *
// 700 - bold

// Acumin Pro
// 100 - thin
// 200 - extra light
// 300 - light        *
// 400 - regular      *
// 500 - medium
// 600 - semibold     *
// 700 - bold
// 800 - black
// 900 - ultrablack

// Acumin Pro Cond
// 100 - thin
// 200 - extra light
// 300 - light        *
// 400 - regular      *
// 500 - medium       *
// 600 - semibold     *
// 700 - bold         *
// 800 - black
// 900 - ultrablack



/*================ FONTS & COLORS ================*/




$color-beige-light: #f8f6f1;
$color-beige-lighter: #fafaf8;
$color-red: #b73e25;
$color-red-alt: #953c27;
$color-army: #676845;
$color-purple: #763D47;
$color-beiger: #d7d2cc;

$newbeige: #f4f1ea;
$newdarkbeige: #e0dcd0;
$notblack: #1c1a1f;

$font-acumin: acumin-pro, Helvetica, sans-serif;
$font-acumincond: acumin-pro-condensed, Helvetica, sans-serif;
$font-mono: Overpass Mono,monospace;
$font-mason: 'MasonGothic', $font-acumincond;
$font-caslon: "big-caslon-fb", serif;
$font-proxw: proxima-nova-wide, $font-acumin;

@mixin bold_head {
  font-family: $font-acumincond;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

@mixin font-overp {
  font-family: $font-mono;
  font-variant-ligatures: no-common-ligatures;
}


/*================ MEDIA QUERY BREAKPOINTS ================*/

/* widths */
$desktop-min: 1440px;
$desktopmd-max: 1439px;
$desktopmd-min: 1121px;
$desktopsm-max: 1120px;
$desktopsm-min: 820px;
$mobile-max: 819px;

$deskmed_min: 821px;
$deskmed_max: 950px;


@mixin screendm($screen) {
  @if $screen == desktop {
    @media only screen and (min-width: $desktopsm-min) {
      @content;
    }
  }
  @else if $screen == deskmed {
    @media only screen and (min-width: $desktopmd-min)  {
      @content;
    }
  }
  @else if $screen == desksm {
    @media only screen and (min-width: $mobile-max) and (max-width: $desktopsm-max)  {
      @content;
    }
  }
  @else if $screen == mobile {
    @media only screen and (max-width: $mobile-max) {
      @content;
    }
  }
}

@mixin ovride-nest {
  & {
    @content;
  }
}


@mixin smoothfont {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}



// NEW CONTAINER -------------------------------------------------------------------

@mixin aria_btn {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: inherit;
  border: 0;
  padding: 0;
  position: relative;
  line-height: inherit;
  text-align: initial;
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin panel {
  @include smoothfont;
  background-color: #fff;
  box-sizing: border-box;
  color: #333;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: 100dvh;
  opacity: 0;
  position: fixed;
  right: 0px;
  top: 0px;
  transform: translate(10%,0);
  transition: all .2s ease-out;
  width: 45em;
  pointer-events: none;
  z-index: 500;
  @include screendm(mobile) {
    height: 100%;
    width: 100vw;
  }
  &[aria-hidden="false"] {
    opacity: 1;
    pointer-events: initial;
    transform: translate(0,0);
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin panel_close {
  padding: 1.25em 0.8em 0.8em;
  position: absolute;
  right: 1.1em;
  text-decoration: none;
  top: 0px;
  z-index: 10;
  .icon_close {
    background-color: transparent;
    box-shadow: 0 0 0 3px transparent;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    width: 1em;
  }
  &:active .icon_close {
    background-color: #333;
    box-shadow: 0 0 0 3px #333;
    color: #fff;
  }
  @include screendm(desktop) {
    &:hover .icon_close {
      background-color: #333;
      box-shadow: 0 0 0 3px #333;
      color: #fff;
    }
  }
  @include screendm(mobile) {
    right: 0;
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin nd_cont {
  box-sizing: border-box;
  position: relative;
  @include screendm(desktop) {
    margin: 0 auto;
    max-width: 1350px;
    width: 83%;
  }
  @include screendm(mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @include screendm(desksm) {
    padding-left: 2rem;
    padding-right: 2rem;
    width: unset;
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin nd_title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin arr_btn {
  position: relative;
  text-indent: -9999px;
  &:before {
    background-color: currentColor;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-3px,-50%);
    width: calc(100% - 3px);
    z-index: -1;
  }
  &:after {
    border: 5px solid currentColor;
    border-width: 3px 7px;
    border-color: transparent transparent transparent currentColor;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(10px, -50%);
    width: 0;
    z-index: -1;
  }
  @include screendm(desktop) {
    &:hover,
    &:active {
      background-color: transparent;
      &:before {
        background-color: #999;
      }
      &:after {
        border-color: transparent transparent transparent #999;
      }
    }
  }
  &:active {
    background-color: transparent;
    &:before {
      background-color: #999;
    }
    &:after {
      border-color: transparent transparent transparent #999;
    }
  }
  @if content-exists() {
    & {
      @content
    }
  }
} 

@mixin title_size {
  font-size: 1.85rem;
  @include screendm(mobile) {
    font-size: 5vw;
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin nd_cta {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .nd_cta-link {
    margin-left: 2em;
  }
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:active {
    text-decoration: underline;
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin nd_input {
  @include smoothfont;
  @include font-overp;
  background-color: transparent;
  border: 1px solid currentColor;
  border-width: 0 0 1px;
  box-shadow: none;
  color: inherit;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 1em 0;
  &::-webkit-input-placeholder,
  &::placeholder {
    @include font-overp;
    color: #aaa;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border-color: currentColor;
    color: inherit;
    -webkit-text-fill-color: inherit;
  }
  @include screendm(mobile) {
    font-size: 16px;
  }
  @if content-exists() {
    & {
      @content
    }
  }
}

@mixin blog_body {
  @include smoothfont;
  @include font-overp;
  font-weight: 400;
}

@mixin blog_title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}







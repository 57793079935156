@import './mixins'; 


.appwr {
  @include nd_cont;
  padding-top: 3em;
}

.hist_back {
  @include nd_title;
  font-size: 1.2em;
  text-decoration: none;
  svg {
    height: auto;
    transform: rotate(90deg);
    margin-right: 0.25em;
    width: 0.8em;
  }
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.hist_sep {
  margin: 0 0.25em;
}


.abtn {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: unset;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  border: 0;
  padding: 0;
  position: relative;
  line-height: inherit;
  text-align: initial;
  @include screendm(desktop) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}

.cta_link {
  @include nd_title;
  font-weight: bold;
  text-decoration: none;
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:before {
    display: inline-block;
    content: '[';
  }
  &:after {
    content: ']';
    display: inline-block;
  }
}

.field {
  position: relative;
  label:not([class]) {
    display: block;
    margin-bottom: 0.5em;
  }
}

.field + .field,
.fieldset + .field,
.field + .fieldset,
.fieldset + .fieldset {
  margin-top: 1em;
}

.fieldset {
  display: flex;
  .field {
    margin-top: 0;
    flex: 1;
    ~ .field {
      margin-left: 1em;
    }
  }
  @include screendm(mobile) {
    &.fieldset-mobile_stack {
      display: block;
      .field ~ .field {
        margin: 1em 0 0;
      }
    }
  }
}

.field_submit {
  margin-top: 1.5em;
  .btn {
    width: 100%;
  }
}

.subh {
  margin: 6rem 0 1.5rem;
}

.note {
  color: #5F5D5D;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.95em;
  margin-top: 6rem;
  > div {
    flex: 1;
  }
  p + p {
    margin-top: 0.5em;
  }
  @include screendm(mobile) {
    > div {
      flex: 1 1 100%;
    }
  }
}

.confirmOpts {
  display: flex;
  margin-top: 3em;
  padding-left: 0;
  list-style: none;
  
  @include screendm(desktop) {
    column-gap: 3em;
    li {
      flex: 1;
      max-width: 35em;
    }
  }
  @include screendm(mobile) {
    flex-direction: column;
    grid-row-gap: 2.5em;
    li {
      flex: 0 1 auto;
    }
    button, a {
      width: 100%;
    }
  }
}

.btnA {
  @include smoothfont;
  background-color: #333;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  font-weight: bold;
  border: 0;
  line-height: 1;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  padding: 1.1rem 1.5rem 1.2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  min-width: 23em;
}





